import {inject} from "aurelia-framework";
import {UserService} from "./UserService";
import * as environment from "../../../config/environment.json";
import {FhirService} from "./FhirService";
import {ConfigService} from "./ConfigService";

@inject(UserService, FhirService)
export class ChatWrapper {
    userService;
    fhirService;
    container;
    wrapper;
    encounterId;

    isReady = false;
    chatOrigin;
    iframeUrl;

    isEnabled = false;
    isChatOpen = false;
    newMessages = 0;

    constructor(userService: UserService, fhirService: FhirService) {
        this.userService = userService;
        this.fhirService = fhirService;
        this.iframeUrl = ConfigService.ChatURL;
        this.chatOrigin = this.iframeUrl ? this.iframeUrl.match(/^https?\:\/\/([^\/?#]+)/i)[0] : '';
    }

    init() {
        this.isEnabled = ConfigService.ChatEnabled;

        if (!ConfigService.ChatEnabled) {
            return;
        }

        window.addEventListener("message", (event) => {
            if (event.origin === this.chatOrigin) {
                const data = event.data.data;

                switch (event.data.name) {
                    case 'ready': {
                        this.isReady = true;
                        this.authenticate();
                        break;
                    }
                    case 'open': {
                        this.isChatOpen = true;
                        this.wrapper.classList.add('open');
                        break;
                    }
                    case 'close': {
                        this.isChatOpen = false;
                        this.wrapper.classList.remove('open');
                        break;
                    }
                    case 'new-messages': {
                        this.newMessages = parseInt(data.count, 10);
                        break;
                    }
                }
            }
        }, false);

        this.container = document.createElement('iframe');
        this.container.id = 'iframe_chat_container';
        this.container.src = this.iframeUrl;
        this.container.style.border = 'none';
        this.container.style.width = '100%';
        this.container.style.height = '100%';
        this.container.style.overflow = 'hidden';
        this.container.style.backgroundColor = "transparent";
        this.container.frameBorder = "0";
        this.container.allowTransparency = "true";
    }

    toggleChat() {
        if (this.isChatOpen) {
            this.closeChat();
        } else {
            this.openChat();
        }
    }

    openChat() {
        this.container.contentWindow.postMessage({
            name: 'chat-open',
            data: {}
        }, this.iframeUrl);
    }

    closeChat() {
        this.container.contentWindow.postMessage({
            name: 'chat-close',
            data: {}
        }, this.iframeUrl);
    }

    authenticate() {
        this.container.contentWindow.postMessage({
            name: 'authenticate',
            data: {
                fhirServer: FhirService.Endpoint,
                hash: sessionStorage.getItem(environment.sessionName),
                practitioner: this.userService.practitioner,
                encounterId: this.encounterId
            }
        }, this.iframeUrl);
    }

    encounterChanged(encounterId) {
        if (!this.isReady || !this.container || !this.container.contentWindow) {
            return;
        }

        this.encounterId = encounterId;
        this.container.contentWindow.postMessage({
            name: 'encounter-changed',
            data: {
                encounterId: this.encounterId
            }
        }, this.iframeUrl);
    }

    attach(wrapper, encounterId) {
        if (!this.container) {
            return;
        }

        this.encounterId = encounterId;
        this.wrapper = wrapper;

        wrapper.appendChild(this.container);
    }
}
