import {inject} from "aurelia-framework";
import {HttpClient, HttpResponseMessage} from "aurelia-http-client";
import {FhirService} from "./FhirService";
import {DialogMessages} from "./DialogMessages";
import {AnalyzeService} from "./analyzeService";
import {fhirEnums} from "../classes/fhir-enums";
import ResourceType = fhirEnums.ResourceType;
import {QuestionnaireService} from "./QuestionnaireService";
import {ConfigService} from "./ConfigService";
import {PatientItem} from "../classes/Patient/PatientItem";

const moment = require("moment");

@inject(FhirService)
export class CarePlanService {
    fhirService: FhirService;

    static readonly uris = {
        'epa2.2': {
            gmtCodeSystem: 'http://nursit-institute.com/fhir/StructureDefinition/gmt',
            assessmentCodeSystem: 'http://nursit-institute.com/fhir/StructureDefinition/planning-assessment',
            diagnoseCodeSystem: 'http://nursit-institute.com/fhir/StructureDefinition/planning-diagnose',
            assessmentToDiagnoseConceptMap: 'http://nursit-institute.com/fhir/ConceptMap/planning-assessment-to-diagnose',
            diagnoseToTasksConceptMap: 'http://nursit-institute.com/fhir/ConceptMap/planning-diagnose-to-tasks',
            gmtValueSet: 'http://nursit-institute.com/fhir/ValueSet/gmt',
            assessmentValueSet: 'http://nursit-institute.com/fhir/ValueSet/planning-assessment',
            diagnoseValueSet: 'http://nursit-institute.com/fhir/ValueSet/planning-diagnose'
        },
        'SEMPA': {
            gmtCodeSystem: 'http://nursit-institute.com/fhir/StructureDefinition/carma-cs',
            assessmentCodeSystem: 'http://nursit-institute.com/fhir/StructureDefinition/sempa-cs',
            diagnoseCodeSystem: 'http://nursit-institute.com/fhir/StructureDefinition/sempadiag-cs',
            assessmentToDiagnoseConceptMap: 'http://nursit-institute.com/fhir/ConceptMap/sempa2diag-cm',
            diagnoseToTasksConceptMap: 'http://nursit-institute.com/fhir/ConceptMap/sempadiag2carma-cm',
            gmtValueSet: 'http://nursit-institute.com/fhir/ValueSet/carma-vs',
            assessmentValueSet: 'http://nursit-institute.com/fhir/ValueSet/sempa-vs',
            diagnoseValueSet: 'http://nursit-institute.com/fhir/ValueSet/sempadiag-vs'
        },
        'SEMPA-Kids': { // Children Wards
            gmtCodeSystem: 'http://nursit-institute.com/fhir/StructureDefinition/carma-cs',
            assessmentCodeSystem: 'http://nursit-institute.com/fhir/StructureDefinition/sempakids-cs',
            diagnoseCodeSystem: 'http://nursit-institute.com/fhir/StructureDefinition/sempakidsdiag-cs',
            assessmentToDiagnoseConceptMap: 'http://nursit-institute.com/fhir/ConceptMap/sempakids2diag-cm',
            diagnoseToTasksConceptMap: 'http://nursit-institute.com/fhir/ConceptMap/sempakidsdiag2carma-cm',
            gmtValueSet: 'http://nursit-institute.com/fhir/ValueSet/carma-vs',
            assessmentValueSet: 'http://nursit-institute.com/fhir/ValueSet/sempakids-vs',
            diagnoseValueSet: 'http://nursit-institute.com/fhir/ValueSet/sempakidsdiag-vs'
        },
        'Sempa-GH': { // Birth Helping Wards
            gmtCodeSystem: 'http://nursit-institute.com/fhir/StructureDefinition/carma-cs',
            assessmentCodeSystem: 'http://nursit-institute.com/fhir/StructureDefinition/sempakids-cs',
            diagnoseCodeSystem: 'http://nursit-institute.com/fhir/StructureDefinition/sempakidsdiag-cs',
            assessmentToDiagnoseConceptMap: 'http://nursit-institute.com/fhir/ConceptMap/sempakids2diag-cm',
            diagnoseToTasksConceptMap: 'http://nursit-institute.com/fhir/ConceptMap/sempakidsdiag2carma-cm',
            gmtValueSet: 'http://nursit-institute.com/fhir/ValueSet/carma-vs',
            assessmentValueSet: 'http://nursit-institute.com/fhir/ValueSet/sempakids-vs',
            diagnoseValueSet: 'http://nursit-institute.com/fhir/ValueSet/sempakidsdiag-vs'
        },
        'VHNOE': {
            gmtCodeSystem: 'http://nursit-institute.com/fhir/StructureDefinition/carma-cs',
            assessmentCodeSystem: 'http://nursit-institute.com/fhir/StructureDefinition/sempa-cs',
            diagnoseCodeSystem: 'http://nursit-institute.com/fhir/StructureDefinition/sempadiag-cs',
            assessmentToDiagnoseConceptMap: 'http://nursit-institute.com/fhir/ConceptMap/sempa2diag-cm',
            diagnoseToTasksConceptMap: 'http://nursit-institute.com/fhir/ConceptMap/sempadiag2carma-cm',
            gmtValueSet: 'http://nursit-institute.com/fhir/ValueSet/carma-vs',
            assessmentValueSet: 'http://nursit-institute.com/fhir/ValueSet/sempa-vs',
            diagnoseValueSet: 'http://nursit-institute.com/fhir/ValueSet/sempadiag-vs'
        },
        'SEMPA-Intensiv': {
            gmtCodeSystem: 'http://nursit-institute.com/fhir/StructureDefinition/carma-cs',
            assessmentCodeSystem: 'http://nursit-institute.com/fhir/StructureDefinition/sempa-cs',
            diagnoseCodeSystem: 'http://nursit-institute.com/fhir/StructureDefinition/sempadiag-cs',
            assessmentToDiagnoseConceptMap: 'http://nursit-institute.com/fhir/ConceptMap/sempa2diag-cm',
            diagnoseToTasksConceptMap: 'http://nursit-institute.com/fhir/ConceptMap/sempadiag2carma-cm',
            gmtValueSet: 'http://nursit-institute.com/fhir/ValueSet/carma-vs',
            assessmentValueSet: 'http://nursit-institute.com/fhir/ValueSet/sempa-vs',
            diagnoseValueSet: 'http://nursit-institute.com/fhir/ValueSet/sempadiag-vs'
        }
    };

    private gmtCodeSystem = {
        'epa2.2': null,
        'SEMPA': null,
        'VHNOE': null,
        'SEMPA-Intensiv': null,
        'SEMPA-Kids': null,
        'SEMPA-GH': null
    };

    private assessmentCodeSystem = {
        'epa2.2': null,
        'SEMPA': null,
        'VHNOE': null,
        'SEMPA-Intensiv': null,
        'SEMPA-Kids': null,
        'SEMPA-GH': null
    };

    private diagnoseCodeSystem = {
        'epa2.2': null,
        'SEMPA': null,
        'VHNOE': null,
        'SEMPA-Intensiv': null,
        'SEMPA-Kids': null,
        'SEMPA-GH': null
    };

    private assessmentToDiagnoseConceptMap = {
        'epa2.2': null,
        'SEMPA': null,
        'VHNOE': null,
        'SEMPA-Intensiv': null,
        'SEMPA-Kids': null,
        'SEMPA-GH': null
    };
    private diagnoseToTasksConceptMap = {
        'epa2.2': null,
        'SEMPA': null,
        'VHNOE': null,
        'SEMPA-Intensiv': null,
        'SEMPA-Kids': null,
        'SEMPA-GH': null
    };

    private gmtCodeSystemForUrls = {};

    static patientAnalyzeType = {};

    constructor(fhirService) {
        this.fhirService = fhirService;
    }

    static async getPatientAnalyzeType(patient) {
        if (!patient) return undefined;
        if (!CarePlanService.patientAnalyzeType[patient.id]) {
            let analyzeType = await AnalyzeService.GetAnalyzerVersion(patient);

            if (analyzeType === "VHNOE") {
                analyzeType = "SEMPA";
            }

            CarePlanService.patientAnalyzeType[patient.id] = analyzeType;
        }

        return CarePlanService.patientAnalyzeType[patient.id];
    }

    static getGmtCodeSystemUri(patient) {
        if (!patient) return null;
        if (!CarePlanService.patientAnalyzeType[patient.id]) {
            console.error('Patient analyze type not fetched for patient ' + patient.id);
            return null;
        }

        return CarePlanService.uris[CarePlanService.patientAnalyzeType[patient.id]].gmtCodeSystem;
    }

    static getAssessmentCodeSystemUri(patient) {
        if (!CarePlanService.patientAnalyzeType[patient.id]) {
            console.error('Patient analyze type not fetched for patient ' + patient.id);
            return null;
        }

        return CarePlanService.uris[CarePlanService.patientAnalyzeType[patient.id]].assessmentCodeSystem;
    }

    static getDiagnoseCodeSystemUri(patient) {
        if (!CarePlanService.patientAnalyzeType[patient.id]) {
            console.error('Patient analyze type not fetched for patient ' + patient.id);
            return null;
        }

        return CarePlanService.uris[CarePlanService.patientAnalyzeType[patient.id]].diagnoseCodeSystem;
    }

    static getAssessmentToDiagnoseConceptMapUri(patient) {
        if (!CarePlanService.patientAnalyzeType[patient.id]) {
            console.error('Patient analyze type not fetched for patient ' + patient.id);
            return null;
        }

        return CarePlanService.uris[CarePlanService.patientAnalyzeType[patient.id]].assessmentToDiagnoseConceptMap;
    }

    static getDiagnoseToTasksConceptMapUri(patient) {
        if (!CarePlanService.patientAnalyzeType[patient.id]) {
            console.error('Patient analyze type not fetched for patient ' + patient.id);
            return null;
        }

        return CarePlanService.uris[CarePlanService.patientAnalyzeType[patient.id]].diagnoseToTasksConceptMap;
    }

    static getGmtValueSetUri(patient) {
        if (!CarePlanService.patientAnalyzeType[patient.id]) {
            console.error('Patient analyze type not fetched for patient ' + patient.id);
            return null;
        }

        return CarePlanService.uris[CarePlanService.patientAnalyzeType[patient.id]].gmtValueSet;
    }

    async getAssessmentValueSetUri(patient) {
        if (!CarePlanService.patientAnalyzeType[patient.id]) {
            console.error('Patient analyze type not fetched for patient ' + patient.id);
            return null;
        }

        return CarePlanService.uris[CarePlanService.patientAnalyzeType[patient.id]].assessmentValueSet;
    }

    async getGMTCodeSystem(patient) {
        let analyzeType: string = await CarePlanService.getPatientAnalyzeType(patient) || "epa2.2";

        if (this.gmtCodeSystem[analyzeType.toUpperCase()])
            analyzeType = analyzeType.toUpperCase();

        if (!this.gmtCodeSystem[analyzeType]) {
            if (!CarePlanService.uris[analyzeType]) {
                /* if (analyzeType === 'VHNOE') {
                    console.warn(`No AnalyzeType for type "${analyzeType}" found. Falling back to "SEMPA"`);
                    analyzeType = 'SEMPA';
                }
                else { */
                let origType = analyzeType;
                analyzeType = !analyzeType || analyzeType.toUpperCase().indexOf('SEMPA') === -1 ? 'epa2.2' : 'SEMPA';
                console.warn(`No AnalyzeType for type "${origType}" found. Falling back to "${analyzeType}"`);
                /* } */
            }

            [this.gmtCodeSystem[analyzeType]] = await this.fhirService.fetch(`CodeSystem?url=${CarePlanService.uris[analyzeType].gmtCodeSystem}`);
        }

        if (!this.gmtCodeSystem[analyzeType]) {
            console.warn(`No CodeSystem: ${CarePlanService.uris[analyzeType].gmtCodeSystem}`);
        }

        if (!this.gmtCodeSystemForUrls[CarePlanService.uris[analyzeType].gmtCodeSystem]) {
            this.gmtCodeSystemForUrls[CarePlanService.uris[analyzeType].gmtCodeSystem] = this.gmtCodeSystem[analyzeType];
        }

        return this.gmtCodeSystem[analyzeType];
    }

    async getGMTCodeSystemByUri(url) {
        if (!this.gmtCodeSystemForUrls[url]) {
            [this.gmtCodeSystemForUrls[url]] = await this.fhirService.fetch(`CodeSystem?url=${url}`);
        }

        if (!this.gmtCodeSystemForUrls[url]) {
            console.warn(`No CodeSystem for URL: ${url}`);
        }

        return this.gmtCodeSystemForUrls[url];
    }

    static warningMessageShown: boolean = false;

    async getAssessmentCodeSystem(patient) {
        const analyzeType = await CarePlanService.getPatientAnalyzeType(patient);

        if (!this.assessmentCodeSystem[analyzeType]) {
            [this.assessmentCodeSystem[analyzeType]] = await this.fhirService.fetch(`CodeSystem?url=${CarePlanService.uris[analyzeType].assessmentCodeSystem}`);
        }

        if (!this.assessmentCodeSystem[analyzeType]) {
            const msg = `No CodeSystem: ${CarePlanService.uris[analyzeType].assessmentCodeSystem}`;
            console.warn(msg);
            if (!CarePlanService.warningMessageShown) {
                CarePlanService.warningMessageShown = true;
                alert(msg);
            }
        }

        return this.assessmentCodeSystem[analyzeType];
    }

    async getDiagnoseCodeSystem(patient) {
        const analyzeType = await CarePlanService.getPatientAnalyzeType(patient);

        if (!this.diagnoseCodeSystem[analyzeType]) {
            [this.diagnoseCodeSystem[analyzeType]] = await this.fhirService.fetch(`CodeSystem?url=${CarePlanService.uris[analyzeType].diagnoseCodeSystem}`);
        }

        if (!this.diagnoseCodeSystem[analyzeType]) {
            console.warn(`No CodeSystem: ${CarePlanService.uris[analyzeType].diagnoseCodeSystem}`);
        }

        return this.diagnoseCodeSystem[analyzeType];
    }

    async getAssessmentToDiagnoseConceptMap(patient) {
        const analyzeType = await CarePlanService.getPatientAnalyzeType(patient);

        if (!this.assessmentToDiagnoseConceptMap[analyzeType]) {
            [this.assessmentToDiagnoseConceptMap[analyzeType]] = await this.fhirService.fetch(`ConceptMap?url=${CarePlanService.uris[analyzeType].assessmentToDiagnoseConceptMap}`);
        }

        if (!this.assessmentToDiagnoseConceptMap[analyzeType]) {
            console.warn(`No ConceptMap: ${CarePlanService.uris[analyzeType].assessmentToDiagnoseConceptMap}`);
        }

        return this.assessmentToDiagnoseConceptMap[analyzeType];
    }

    async getDiagnoseToTasksConceptMap(patient) {
        const analyzeType = await CarePlanService.getPatientAnalyzeType(patient);

        if (!this.diagnoseToTasksConceptMap[analyzeType]) {
            [this.diagnoseToTasksConceptMap[analyzeType]] = await this.fhirService.fetch(`ConceptMap?url=${CarePlanService.uris[analyzeType].diagnoseToTasksConceptMap}`);
        }

        if (!this.diagnoseToTasksConceptMap[analyzeType]) {
            console.warn(`No ConceptMap: ${CarePlanService.uris[analyzeType].diagnoseToTasksConceptMap}`);
        }

        return this.diagnoseToTasksConceptMap[analyzeType];
    }

    async loadCarePlans(encounterId) {
        let context = `${ResourceType.encounter}/${encounterId}`;

        let url = `${ResourceType.carePlan}?${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}=${context}`;

        return (await this.fhirService.fetch(url)).sort(CarePlanService.sortCarePlans);
    }

    async loadLatestAssessment(patient) {
        if (patient?.latestAssessment)
            return patient.latestAssessment;

        const assessmentName = PatientItem.GetQuestionnaireName(patient, 'assessment');
        let assessment = QuestionnaireService.GetLatestResponseOfName(patient, assessmentName, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);

        if (patient && assessment && !patient.latestAssessment) {
            patient.latestAssessment = assessment;
        }

        return patient?.latestAssessment;
    }

    async loadCarePlanAssessment(carePlan, latestAssessment) {
        if (!carePlan) {
            return null;
        }

        const qList = await QuestionnaireService.GetQuestionnaireIds();
        const assessmentId = carePlan.supportingInfo && carePlan.supportingInfo[0] && carePlan.supportingInfo[0].reference.split('/')[1];
        const assessmentQuestionnaire = await QuestionnaireService.GetQuestionnaireById(qList.QAssessmentId);
        let assessment;

        if (!assessmentId) {
            assessment = latestAssessment;
        } else if (latestAssessment && assessmentId !== latestAssessment.id) {
            try {
                assessment = await this.fhirService.get(`${ResourceType.questionnaireResponse}/${assessmentId}`);

                if (FhirService.FhirVersion > 3) {
                    const [questionnaireCanonical] = assessment?.questionnaire.split('|');

                    if (questionnaireCanonical !== assessmentQuestionnaire?.url) {
                        assessment = null;
                    }
                } else {
                    if (assessment.questionnaire.reference.indexOf(`Questionnaire/${assessmentQuestionnaire.id}`) === -1) {
                        assessment = null;
                    }
                }
            } catch (e) {
                console.warn(e.message || e);
                assessment = null;
            }
        } else {
            assessment = latestAssessment;
        }

        return assessment;
    }

    async getMappings(patient) {
        const assessmentCodeSystem = await this.getAssessmentCodeSystem(patient);
        const diagnoseCodeSystem = await this.getDiagnoseCodeSystem(patient);
        const assessmentToDiagnose = await this.getAssessmentToDiagnoseConceptMap(patient);
        const diagnoseToTasks = await this.getDiagnoseToTasksConceptMap(patient);

        if (!assessmentToDiagnose) {
            return [];
        }

        return assessmentToDiagnose.group[0].element.map((element) => {
            const assessmentParent = element && element.code ? CarePlanService.findCodeParent(element.code, assessmentCodeSystem, 2) : null;
            const assessmentGroup = assessmentParent && assessmentParent.code ? CarePlanService.findCodeParent(assessmentParent.code, assessmentCodeSystem) : null;
            const diagnose = element.target[0];
            const diagnoseParent = CarePlanService.findCodeParent(diagnose.code, diagnoseCodeSystem);
            const diagnoseTaskMapping = CarePlanService.findConceptMapping(diagnose.code, diagnoseToTasks);

            if (!diagnoseParent) {
                return null;
            }

            return {
                assessmentId: assessmentParent?.code,
                assessmentValueId: element?.code,
                diagnoseID: diagnose?.code,
                diagnoseText: diagnose?.display,
                taskIds: diagnoseTaskMapping?.map((task) => task.code),
                groupTitle: assessmentGroup?.display,
                groupId: assessmentGroup?.code,
            };
        }).filter(item => item);
    }

    static getLatestActiveCarePlan(carePlans) {
        // this requires that the array is already sorted by date
        return carePlans.find((cp) => cp.status === 'active');
    }

    static isDiagnosesOutdated(carePlan, carePlanAssessment, latestAssessment) {
        if (!carePlan || !latestAssessment) {
            return false;
        }

        return (carePlanAssessment && carePlanAssessment.id !== latestAssessment.id || !carePlanAssessment);
    }

    static sortCarePlans(a, b) {
        const dateA = moment((a.period && (a.period.end || a.period.start)) || a.meta.lastUpdated);
        const dateB = moment((b.period && (b.period.end || b.period.start)) || b.meta.lastUpdated);

        if (dateA.isAfter(dateB)) return -1;

        return 1;
    }

    static findConceptMapping(code, conceptMap) {
        let mapping = null;

        conceptMap.group.forEach((group) => {
            group.element.forEach((element) => {
                if (element.code === code) {
                    mapping = element.target;
                }
            });
        });

        return mapping;
    }

    static findCode(code, codeSystem, minDepth = 0) {
        let codeData = null;

        function recursiveFind(concepts, depth) {
            for (let i = 0; i < concepts.length; i++) {
                const concept = concepts[i];

                if (concept.code == code && depth >= minDepth) {
                    codeData = concept;
                }

                if (concept.concept) {
                    recursiveFind(concept.concept, depth + 1);
                }
            }
        }

        recursiveFind(codeSystem.concept, 0);

        return codeData;
    }

    static findCodeParent(code, codeSystem, minDepth = 0) {
        let codeParent = null;

        function recursiveFind(concepts, parent, depth) {
            for (let i = 0; i < concepts.length; i++) {
                const concept = concepts[i];

                if (concept.code == code && depth >= minDepth) {
                    codeParent = parent;
                }

                if (concept.concept) {
                    recursiveFind(concept.concept, concept, depth + 1);
                }
            }
        }

        if (codeSystem && codeSystem.concept)
            recursiveFind(codeSystem.concept, null, 0);

        return codeParent;
    }

    static findCodeRoot(code, codeSystem) {
        let codeRoot = null;

        function recursiveFind(concepts, parent) {
            for (let i = 0; i < concepts.length; i++) {
                const concept = concepts[i];

                if (concept.code == code) {
                    codeRoot = parent;
                }

                if (concept.concept) {
                    recursiveFind(concept.concept, parent || concept);
                }
            }
        }

        if (codeSystem && codeSystem.concept)
            recursiveFind(codeSystem.concept, null);

        return codeRoot;
    }

    static getConceptProperty(prop, concept) {
        if (concept.property) {
            for (let i = 0; i < concept.property.length; i++) {
                if (concept.property[i].code === prop) {
                    const p = concept.property[i];

                    if (p.hasOwnProperty('valueCode')) return p.valueCode;
                    if (p.hasOwnProperty('valueCoding')) return p.valueCoding;
                    if (p.hasOwnProperty('valueString')) return p.valueString;
                    if (p.hasOwnProperty('valueInteger')) return p.valueInteger;
                    if (p.hasOwnProperty('valueBoolean')) return p.valueBoolean;
                    if (p.hasOwnProperty('valueDateTime')) return p.valueDateTime;
                }
            }
        }

        return null;
    }

    static parseCodeSystem(concept) {
        const properties = {};
        let durations = [];

        if (!concept) {
            return null;
        }

        if (concept.property) {
            concept.property.forEach((prop) => {
                let val;

                if (prop.hasOwnProperty('valueInteger')) {
                    val = prop.valueInteger;
                } else if (prop.hasOwnProperty('valueBoolean')) {
                    val = prop.valueBoolean;
                }

                properties[prop.code] = val;
            });
        }

        if (concept.extension) {
            durations = concept.extension.filter(ext => ext.valueTiming).map(ext => CarePlanService.parseCodeSystemDuration(ext.valueTiming));
        }

        return {
            code: concept.code,
            display: concept.display,
            properties: {
                defaultDuration: properties['default-duration'] || 0,
                personsNeeded: properties['persons-needed'] || 0,
                prescNeeded: properties['presc-needed'] || false,
                varProcedure: properties['var-procedure'] || -1
            },
            durations
        };
    }

    static parseCodeSystemDuration(valueTiming) {
        if (valueTiming?.repeat?.periodUnit) {
            return {
                type: 'interval',
                periodUnit: valueTiming.repeat.periodUnit,
                period: valueTiming.repeat.period,
                frequency: valueTiming.repeat.frequency
            };
        } else {
            const code = valueTiming?.code?.coding[0]?.code;

            return {
                type: code
            };
        }
    }

    static countProcedureIntervals(procedureRequest, minTimeslot, maxTimeslot, today, duration) {
        const procedureRequestStart = moment(procedureRequest.occurrenceTiming.event[0]);
        const procedureRequestEnd = procedureRequest.occurrenceTiming.event[1] ? moment(procedureRequest.occurrenceTiming.event[1]) : null;

        let total = 0;

        if (duration.type !== 'interval') {
            return total;
        }

        if (duration.frequency > 0) {
            if (duration.periodUnit === 'wk') {
                const weekDays = [
                    'sun',
                    'mon',
                    'tue',
                    'wed',
                    'thu',
                    'fri',
                    'sat'
                ];

                if (procedureRequest.occurrenceTiming.repeat.dayOfWeek) {
                    procedureRequest.occurrenceTiming.repeat.dayOfWeek.forEach((dayOfWeek) => {
                        if (weekDays[today.day()] === dayOfWeek) {
                            total++;
                        }
                    });
                }
            } else {
                const totalLengthHours = 14;
                const step = moment(today).add(6, 'hours');
                let num, distance;

                switch (duration.periodUnit) {
                    case 'd': {
                        num = duration.frequency;
                        distance = (totalLengthHours / duration.frequency) * 60;
                        break;
                    }
                    case 'h': {
                        num = totalLengthHours * duration.frequency;
                        distance = 60 / duration.frequency;
                        break;
                    }
                    case 'min': {
                        num = totalLengthHours * 60 * duration.frequency;
                        distance = 60 / 60 / duration.frequency;
                        break;
                    }
                }

                for (let i = 0; i < num; i++) {
                    step.add(distance, 'minutes');

                    if (step.isSameOrAfter(procedureRequestStart) && (!procedureRequestEnd || step.isSameOrBefore(procedureRequestEnd)) && step.isSameOrAfter(minTimeslot) && step.isBefore(maxTimeslot)) {
                        total++;
                    }
                }
            }
        } else {
            const step = moment(procedureRequestStart);
            let distance = 9000;

            switch (duration.periodUnit) {
                case 'd': {
                    distance = 24 * 60 * duration.period;
                    break;
                }
                case 'h': {
                    distance = 60 * duration.period;
                    break;
                }
                case 'min': {
                    distance = duration.period;
                    break;
                }
            }

            while (step.isBefore(maxTimeslot)) {
                if ((!procedureRequestEnd || step.isSameOrBefore(procedureRequestEnd)) && step.isSameOrAfter(minTimeslot)) {
                    total++;
                }

                step.add(distance, 'minutes');
            }
        }

        return total;
    }

    static tempConvertProcedureRequest(patient, procedureRequest, gmtCodeSystem) {
        const legacyCode = procedureRequest.code.coding.find((coding) => coding.system.endsWith('/procedure-request'));

        if (legacyCode) {
            if (legacyCode.code.split('.')[0] == 99) {
                return null;
            }

            const codeData = CarePlanService.findCode(legacyCode.code, gmtCodeSystem);

            if (codeData) {
                const parsedCodeSystem = CarePlanService.parseCodeSystem(codeData);
                const dateTimeSystem = procedureRequest.code.coding.find((c) => c.system.endsWith('/task-begin'));
                let duration;

                if (codeData.extension) {
                    duration = codeData.extension[0];
                } else {
                    // fallback
                    duration = {
                        "url": "http://nursit-institute.com/fhir/StructureDefinition/gmt-duration",
                        "valueTiming": {
                            "code": {
                                "coding": [{
                                    "system": "http://nursit-institute.com/fhir/StructureDefinition/gmt-timing",
                                    "code": "as-needed",
                                    "display": "As Needed"
                                }]
                            }
                        }
                    };
                }

                procedureRequest.code = {
                    coding: [{
                        system: CarePlanService.getGmtCodeSystemUri(patient),
                        code: codeData.code,
                        display: codeData.display
                    }]
                };
                procedureRequest.occurrenceTiming = duration.valueTiming;

                if (!procedureRequest.occurrenceTiming.repeat) {
                    procedureRequest.occurrenceTiming.repeat = {};
                }

                procedureRequest.occurrenceTiming.repeat.duration = parsedCodeSystem.properties.defaultDuration;

                procedureRequest.occurrenceTiming.event = [moment(dateTimeSystem.code).toJSON()];
            }
        }

        return procedureRequest;
    }

    static tempConvertProcedure(patient, procedure) {
        procedure.code.coding[0].system = CarePlanService.getGmtCodeSystemUri(patient);

        return procedure;
    }

    // use only to generate mappings to codesystems/valuesets/conceptmaps
    // async generateMappings() {
    //     await this.getGMTCodeSystem();
    //
    //     return new Promise((resolve, reject) => {
    //         new HttpClient().get("./config/Mapping_Assessment_Diagnosen_Massnahmen.json")
    //             .then(async (msg: HttpResponseMessage) => {
    //                 try {
    //                     let json = JSON.parse(msg.response);
    //
    //                     let [gmtValueSet] = await this.fhirService.fetch(`ValueSet?url=${CarePlanService.gmtValueSet}`);
    //
    //                     if (!gmtValueSet) {
    //                         gmtValueSet = await this.fhirService.create({
    //                             "resourceType": "ValueSet",
    //                             "name": "gmt",
    //                             "title": "GMT",
    //                             "publisher": "NursIT Institute",
    //                             "url": CarePlanService.gmtValueSet,
    //                             "status": "active",
    //                             "date": "2021-02-24",
    //                             "version": "1.0",
    //                             "experimental": false,
    //                             "compose": {
    //                                 "include": [
    //                                     {
    //                                         "system": CarePlanService.gmtCodeSystemUri
    //                                     }
    //                                 ],
    //                                 "exclude": []
    //                             },
    //                         })
    //                     }
    //
    //                     gmtValueSet.compose = {
    //                         "include": [
    //                             {
    //                                 "system": CarePlanService.gmtCodeSystemUri
    //                             }
    //                         ],
    //                         "exclude": []
    //                     };
    //
    //                     gmtValueSet = await this.fhirService.update(gmtValueSet);
    //
    //
    //                     let [assessmentCodesystem] = await this.fhirService.fetch(`CodeSystem?url=${CarePlanService.assessmentCodeSystemUri}`);
    //
    //                     if (!assessmentCodesystem) {
    //                         assessmentCodesystem = await this.fhirService.create({
    //                             resourceType: "CodeSystem",
    //                             url: CarePlanService.assessmentCodeSystemUri,
    //                             version: "1.0",
    //                             name: "planning-assessment",
    //                             title: "Planning Assessment",
    //                             status: "active",
    //                             experimental: false,
    //                             date: "2021-02-24",
    //                             publisher: "NursIT Institute",
    //                         });
    //                     }
    //
    //                     assessmentCodesystem.concept = [];
    //
    //                     json.forEach((item) => {
    //                         if (!assessmentCodesystem.concept.find((c) => c.code === item.groupId)) {
    //                             assessmentCodesystem.concept.push({
    //                                 code: item.groupId,
    //                                 display: item.groupTitle,
    //                                 concept: []
    //                             })
    //                         }
    //
    //                         const groupParent = assessmentCodesystem.concept.find((c) => c.code === item.groupId);
    //
    //                         if (!groupParent.concept.find((c) => c.code === item.assessmentId)) {
    //                             groupParent.concept.push({
    //                                 code: item.assessmentId,
    //                                 concept: []
    //                             })
    //                         }
    //
    //                         const assessmentParent = groupParent.concept.find((c) => c.code === item.assessmentId);
    //
    //                         if (assessmentParent) {
    //                             assessmentParent.concept.push({
    //                                 code: item.assessmentValueId,
    //                                 display: item.assessmentText
    //                             })
    //                         }
    //                     });
    //
    //                     assessmentCodesystem = await this.fhirService.update(assessmentCodesystem);
    //
    //
    //                     let [diagnoseCodesystem] = await this.fhirService.fetch(`CodeSystem?url=${CarePlanService.diagnoseCodeSystemUri}`);
    //
    //                     if (!diagnoseCodesystem) {
    //                         diagnoseCodesystem = await this.fhirService.create({
    //                             resourceType: "CodeSystem",
    //                             url: CarePlanService.diagnoseCodeSystemUri,
    //                             version: "1.0",
    //                             name: "planning-diagnose",
    //                             title: "Planning Diagnose",
    //                             status: "active",
    //                             experimental: false,
    //                             date: "2021-02-24",
    //                             publisher: "NursIT Institute",
    //                         });
    //                     }
    //
    //                     diagnoseCodesystem.concept = [];
    //
    //                     json.forEach((item) => {
    //                         if (!item.diagnoseLinkId) return;
    //
    //                         if (!diagnoseCodesystem.concept.find((c) => c.code === item.diagnoseLinkId)) {
    //                             diagnoseCodesystem.concept.push({
    //                                 code: item.diagnoseLinkId,
    //                                 concept: []
    //                             })
    //                         }
    //
    //                         const parent = diagnoseCodesystem.concept.find((c) => c.code === item.diagnoseLinkId);
    //
    //                         if (parent) {
    //                             parent.concept.push({
    //                                 code: item.diagnoseID,
    //                                 display: item.diagnoseText
    //                             })
    //                         }
    //                     });
    //
    //                     diagnoseCodesystem = await this.fhirService.update(diagnoseCodesystem);
    //
    //
    //                     let [assessmentValueset] = await this.fhirService.fetch(`ValueSet?url=${CarePlanService.assessmentValueSetUri}`);
    //
    //                     if (!assessmentValueset) {
    //                         assessmentValueset = await this.fhirService.create({
    //                             "resourceType": "ValueSet",
    //                             "name": "planning-assessment",
    //                             "title": "Planning Assessment",
    //                             "publisher": "NursIT Institute",
    //                             "url": CarePlanService.assessmentValueSetUri,
    //                             "status": "active",
    //                             "date": "2021-02-24",
    //                             "version": "1.0",
    //                             "experimental": false,
    //                             "compose": {
    //                                 "include": [
    //                                     {
    //                                         "system": CarePlanService.assessmentCodeSystemUri
    //                                     }
    //                                 ],
    //                                 "exclude": []
    //                             },
    //                         })
    //                     }
    //
    //
    //                     let [diagnoseValueset] = await this.fhirService.fetch(`ValueSet?url=${CarePlanService.diagnoseValueSetUri}`);
    //
    //                     if (!diagnoseValueset) {
    //                         diagnoseValueset = await this.fhirService.create({
    //                             "resourceType": "ValueSet",
    //                             "name": "planning-diagnose",
    //                             "title": "Planning Diagnose",
    //                             "publisher": "NursIT Institute",
    //                             "url": CarePlanService.diagnoseValueSetUri,
    //                             "status": "active",
    //                             "date": "2021-02-24",
    //                             "version": "1.0",
    //                             "experimental": false,
    //                             "compose": {
    //                                 "include": [
    //                                     {
    //                                         "system": CarePlanService.diagnoseCodeSystemUri
    //                                     }
    //                                 ],
    //                                 "exclude": []
    //                             },
    //                         })
    //                     }
    //
    //
    //                     let [assessmentToDiagnoseConceptMap] = await this.fhirService.fetch(`ConceptMap?url=${CarePlanService.assessmentToDiagnoseConceptMapUri}`);
    //
    //                     if (!assessmentToDiagnoseConceptMap) {
    //                         assessmentToDiagnoseConceptMap = await this.fhirService.create({
    //                             "resourceType": "ConceptMap",
    //                             "name": "planning-assessment-to-diagnose",
    //                             "title": "Planning Assessment to Diagnose",
    //                             "publisher": "NursIT Institute",
    //                             "url": CarePlanService.assessmentToDiagnoseConceptMapUri,
    //                             "description": "",
    //                             "status": "draft",
    //                             "date": "2021-02-24",
    //                             "version": "",
    //                             "experimental": false,
    //                             "sourceUri": CarePlanService.assessmentValueSetUri,
    //                             "targetUri": CarePlanService.diagnoseValueSetUri,
    //                         })
    //                     }
    //
    //                     assessmentToDiagnoseConceptMap.group = [
    //                         {
    //                             source: CarePlanService.assessmentCodeSystemUri,
    //                             target: CarePlanService.diagnoseCodeSystemUri,
    //                             element: []
    //                         }
    //                     ]
    //
    //                     json.forEach((item) => {
    //                         assessmentToDiagnoseConceptMap.group[0].element.push({
    //                             code: item.assessmentValueId,
    //                             display: item.assessmentText,
    //                             target: [{
    //                                 code: item.diagnoseID,
    //                                 display: item.diagnoseText
    //                             }]
    //                         })
    //                     });
    //
    //                     assessmentToDiagnoseConceptMap = await this.fhirService.update(assessmentToDiagnoseConceptMap);
    //
    //
    //                     let [diagnoseToTasksConceptMap] = await this.fhirService.fetch(`ConceptMap?url=${CarePlanService.diagnoseToTasksConceptMapUri}`);
    //
    //                     if (!diagnoseToTasksConceptMap) {
    //                         diagnoseToTasksConceptMap = await this.fhirService.create({
    //                             "resourceType": "ConceptMap",
    //                             "name": "diagnose-to-tasks",
    //                             "title": "Planning Diagnose to Tasks",
    //                             "publisher": "NursIT Institute",
    //                             "url": CarePlanService.diagnoseToTasksConceptMapUri,
    //                             "description": "",
    //                             "status": "draft",
    //                             "date": "2021-02-24",
    //                             "version": "",
    //                             "experimental": false,
    //                             "sourceUri": CarePlanService.diagnoseValueSetUri,
    //                             "targetUri": CarePlanService.gmtValueSet,
    //                         })
    //                     }
    //
    //                     diagnoseToTasksConceptMap.group = [
    //                         {
    //                             source: CarePlanService.diagnoseCodeSystemUri,
    //                             target: CarePlanService.gmtCodeSystemUri,
    //                             element: []
    //                         }
    //                     ]
    //
    //                     json.forEach((item) => {
    //                         const element = {
    //                             code: item.diagnoseID,
    //                             display: item.diagnoseText,
    //                             target: []
    //                         };
    //
    //                         item.taskIds.forEach((taskId) => {
    //                             const task = CarePlanService.findCode(taskId, this.gmtCodeSystem);
    //
    //
    //                             if (task) {
    //                                 element.target.push({
    //                                     code: task.code,
    //                                     display: task.display
    //                                 })
    //                             }
    //                         })
    //
    //                         diagnoseToTasksConceptMap.group[0].element.push(element);
    //                     });
    //
    //                     diagnoseToTasksConceptMap = await this.fhirService.update(diagnoseToTasksConceptMap);
    //
    //
    //                     resolve(json);
    //                 } catch (e) {
    //                     console.warn(e);
    //                     reject("Error when reading Mappings");
    //                 }
    //             })
    //     })
    // }
}
